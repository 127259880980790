const colors = {
  transparent: 'transparent',
  lightGray: '#EDEDEF',
  primary: '#0B0044',
  primaryHover: '#ECF22F',
  primary2: 'rgba(252, 242, 43, 0.25)',
  secondary: '#7440EB',
  secondary2: '#AE60F5',
  secondaryHover: '#AE60F5',
  yellow: '#f5e21b',
  yellowHover: '#f5e21b',
  border: '#dadada',
  black: '#0B0044',
  white: '#ffffff',
  uiWhiteBlueish: '#f5f5fe',
  heading: '#0B0044',
  text: '#294859',
  secondaryText: '#616970',
  secondaryText: '#6c6791',
  lightText: 'rgba(41, 72, 89, 0.5)',
  linkedin: '#0077B5',
  facebook: '#3b5998',
  twitter: '#38A1F3',
  github: '#211f1f',
  select: '#525F7F',
  darkBlue: '#0B0044', // 1
  logoPurple: '#6C0BEA', // 2
  logoPink: '#D44391', // 3
  uiPink: '#D44391', // 3
  uiPurple1: '#5F33E9', // 4
  uiPurple2: '#7440EB', // 5
  uiPurple3: '#AE60F5', // 6
  uiBlue: '#2F3068', // 8
  uiGreen: '#27E8A2', // 9
  uiYellow: '#f5e21b', // 10
  uiOrange: '#E8674A', // 11
  uiBlueLink: '#4517ff',
  uiBlueLinkHover: '#175fff',
  uiBlueLight: '#6b6d96',
  uiBlueLighter: '#b5b5ce',
  uiPurpleBlue: 'rgb(75,15,209)',
  uiPurpleBlueGradient:
    'linear-gradient(0deg, rgba(75,15,209,1) 0%, rgba(69,23,240,1) 100%)',
  uiRMGradient:
    'linear-gradient(-74deg, #752780 0%, #48166c 72%)',
};

export const boxShadow =
  '0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08)';

// const remoteMentors: [
//   '#ffffff', // 0
//   '#0B0044', // 1
//   '#6C0BEA', // 2
//   '#D44391', // 3
//   '#5F33E9', // 4
//   '#7440EB', // 5
//   '#AE60F5', // 6
//   '#EDEDEF', // 7
//   '#2F3068', // 8
//   '#27E8A2', // 9
//   '#f5e21b', // 10
//   '#E8674A', // 11
// ],

export default colors;
